import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

// Services
import {
  addMarbleScene,
  deleteMarbleScene,
  fetchMarbleScenes,
  updateMarbleScene,
} from "../services/marbleScenes";

// Hooks
import { useFilter } from "../hooks/useFilter";

// Components
import { PreviewScene } from "../components/PreviewScene";
import { SearchInput } from "../components/SearchInput";

// Constants
import {
  BACKGROUND_IMAGES,
  DEFAULT_LINE_WIDTH,
  SCENE_STATES,
} from "../constants/constants";

// Prime React Components
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Menu } from "primereact/menu";
import ScenesFlagsReport from "../components/ScenesFlagsReport";

export const Scenes = () => {
  const navigate = useNavigate();
  const componentMount = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { onGlobalFilterChange, globalFilterValue, filters } = useFilter();
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [modal, setModal] = useState({ show: false, scene: {} });
  const [flagsReportVisible, setFlagsReportVisible] = useState(false);
  const { data: scenes, isLoading } = useQuery({
    queryKey: ["scenes"],
    queryFn: fetchMarbleScenes,
  });

  // useEffect(() => {
  //   const updatedScenes = scenes?.map((scene) => {
  //     return {
  //       id: scene.id,
  //       data: {
  //         ...scene?.data,
  //         physics: {
  //           ...scene?.data?.physics,
  //           ios: { ...scene?.data?.physics?.ios, jumpForce: 83 },
  //         },
  //         chains: scene?.data?.chains?.map((chain) => {
  //           return {
  //             ...chain,
  //             elements: chain?.elements?.map((element) => {
  //               return {
  //                 ...element,
  //                 lineWidth: DEFAULT_LINE_WIDTH,
  //                 obstacles:
  //                   element?.obstacles?.length > 0
  //                     ? element?.obstacles?.map((obstacle) => {
  //                         return { ...obstacle, lineWidth: DEFAULT_LINE_WIDTH };
  //                       })
  //                     : [],
  //               };
  //             }),
  //           };
  //         }),
  //       },
  //     };
  //   });
  //   updateMarbleSceneToNewCollection(updatedScenes);
  // });

  const orderMap = {
    1: "asc",
    "-1": "desc",
  };

  const fieldMap = {
    "data.title": "title",
    "data.state": "state",
    "data.bgImage": "bg-image",
    "data.createdAt.seconds": "createdAt",
    "data.lastEditedAt.seconds": "modifiedAt",
  };

  const onSort = (e) => {
    if (!e.sortOrder) {
      setSortField(null);
      setSortOrder(null);
      searchParams.delete("sort");
      searchParams.delete("order");
      setSearchParams(searchParams, { replace: true });
      return;
    }

    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
    searchParams.set("sort", fieldMap[e.sortField]);
    searchParams.set("order", orderMap[e.sortOrder]);
    setSearchParams(searchParams, { replace: true });
  };

  const getKeyByValue = (obj, value) => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };

  useLayoutEffect(() => {
    setSortField(getKeyByValue(fieldMap, searchParams.get("sort")));
    setSortOrder(getKeyByValue(orderMap, searchParams.get("order")));
    onGlobalFilterChange({ target: { value: searchParams.get("search") } });
  }, []);

  useEffect(() => {
    if (componentMount.current) {
      if (globalFilterValue) {
        searchParams.set("search", globalFilterValue || "");
        setSearchParams(searchParams, { replace: true });
      } else {
        searchParams.delete("search");
        setSearchParams(searchParams, { replace: true });
      }
    }
    componentMount.current = true;
  }, [globalFilterValue]);

  const handleAddScene = () => {
    addMarbleScene()
      .then((res) => navigate(`/scenes/${res}`))
      .catch((err) => console.error(err));
  };

  return (
    <div className="flex justify-center">
      {/* Scene Preview Modal */}
      <Dialog
        header={modal?.scene?.title}
        visible={modal.show}
        style={{
          width: window.innerWidth > window.innerHeight ? "80vh" : "80vw",
        }}
        onHide={() => setModal({ show: false, scene: null })}
      >
        <PreviewScene absoluteScene={modal.scene} editScene={false} />
      </Dialog>

      {/* Delete Scene Confirmation Modal */}
      <ConfirmDialog />
      <Dialog
        header="Flags report for published scenes"
        blockScroll
        resizable={false}
        draggable={false}
        className="w-8/12"
        visible={flagsReportVisible}
        onHide={() => setFlagsReportVisible(false)}
      >
        <ScenesFlagsReport scenes={scenes} />
      </Dialog>
      {/* Scenes Table */}
      <div className="flex w-max flex-col gap-4">
        <DataTable
          header={
            <Header
              globalFilterValue={globalFilterValue}
              onGlobalFilterChange={onGlobalFilterChange}
              onFlagsReportClick={() => setFlagsReportVisible(true)}
            />
          }
          value={scenes}
          loading={isLoading}
          filters={filters}
          globalFilterFields={["data.title"]}
          showGridlines
          paginator
          removableSort
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
          rows={10}
          emptyMessage="No scenes found."
          tableStyle={{
            width: "90vw",
            minWidth: "60rem",
          }}
        >
          <Column
            sortable
            header="ID"
            field="id"
            alignHeader="center"
            className="capitalize"
          />
          <Column
            sortable
            header="Title"
            field="data.title"
            alignHeader="center"
            className="capitalize"
            body={(rowData) => <TitleTemplate rowData={rowData} />}
          />
          <Column
            sortable
            header="State"
            field="data.state"
            alignHeader="center"
            className="capitalize"
            body={(rowData) => <StatusTemplate rowData={rowData} />}
          />
          <Column
            sortable
            header="Background"
            field="data.bgImage"
            alignHeader="center"
            className="capitalize"
            body={(rowData) => <BackgroundTemplate rowData={rowData} />}
          />
          <Column
            sortable
            header="Created Date"
            field="data.createdAt.seconds"
            align="center"
            alignHeader="center"
            className="text-nowrap capitalize"
            body={(rowData) => (
              <DateTimeTemplate date={rowData.data.createdAt.seconds} />
            )}
          />
          <Column
            sortable
            header="Modified Date"
            field="data.lastEditedAt.seconds"
            align="center"
            alignHeader="center"
            className="text-nowrap capitalize"
            body={(rowData) => (
              <DateTimeTemplate
                date={
                  rowData.data.lastEditedAt.seconds ||
                  Math.floor(rowData.data.lastEditedAt.getTime() / 1000)
                }
              />
            )}
          />
          <Column
            header="Actions"
            alignHeader="center"
            body={(rowData) => (
              <ActionsTemplate rowData={rowData} setModal={setModal} />
            )}
          />
        </DataTable>
        {/* Create new Scene Action Button */}
        <div className="self-end">
          <Button
            onClick={handleAddScene}
            severity="success"
            label="Create new scene"
          />
        </div>
      </div>
    </div>
  );
};

const Header = ({
  onGlobalFilterChange,
  globalFilterValue,
  onFlagsReportClick,
}) => {
  return (
    <div className="overflow-hidden rounded-t-lg">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-10">
          <p className="font-inter py-5 font-semibold">Scenes</p>
          <Button
            rounded
            label="Flags Report"
            icon="pi pi-flag-fill"
            onClick={onFlagsReportClick}
          />
        </div>
        <SearchInput
          onGlobalFilterChange={onGlobalFilterChange}
          globalFilterValue={globalFilterValue}
        />
      </div>
    </div>
  );
};

const TitleTemplate = ({ rowData }) => {
  return (
    <div className="flex items-center justify-between gap-4">
      <p>{rowData.data.title}</p>
    </div>
  );
};

const StatusTemplate = ({ rowData }) => {
  const menuRef = useRef();
  const queryClient = useQueryClient();

  const state = Object.keys(SCENE_STATES).find(
    (key) => SCENE_STATES[key].value === rowData.data.state,
  );
  const { [state]: currentStatus, ...restStates } = SCENE_STATES;

  const updateSceneMutation = useMutation({
    mutationFn: ({ id, data }) => {
      updateMarbleScene(id, {
        ...data,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(["scenes"], (oldScenes) =>
        oldScenes.map((scene) =>
          scene.id === variables.id ? variables : scene,
        ),
      );
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const ConfirmationBody = ({ selectedState }) => (
    <div>
      <span>Are you sure you want to change the status of</span>
      <strong className="italic"> {rowData.data.title} </strong>
      <span>from</span>
      <strong className="italic text-red-500"> {rowData.data.state} </strong>
      <span>to</span>
      <strong className="italic text-green-500"> {selectedState}</strong>?
    </div>
  );

  const handleStatusChange = (e) => {
    const selectedState = e.item.value;
    confirmDialog({
      message: <ConfirmationBody selectedState={selectedState} />,
      header: "Confirmation",
      className: "max-w-[95vw] sm:max-w-[50vw]",
      icon: "pi pi-exclamation-triangle",
      accept: () =>
        updateSceneMutation.mutate({
          id: rowData.id,
          data: {
            ...rowData.data,
            state: selectedState,
            lastEditedAt: new Date(),
          },
        }),
    });
  };

  const menuItems = [
    {
      label: "Status",
      items: Object.values(restStates).map((item) => ({
        ...item,
        command: handleStatusChange,
      })),
    },
  ];

  return (
    <div className="flex items-center justify-center gap-4">
      <Menu model={menuItems} popup ref={menuRef} />
      {state ? (
        <Tag
          rounded
          severity={SCENE_STATES[state].severity}
          className="cursor-pointer"
          onClick={(e) => {
            menuRef.current.toggle(e);
          }}
        >
          <div className="flex items-center justify-between gap-2 px-2">
            <span>{SCENE_STATES[state].label}</span>
            <i className="pi pi-angle-down"></i>
          </div>
        </Tag>
      ) : null}
    </div>
  );
};

const BackgroundTemplate = ({ rowData }) => {
  return (
    <div className="flex items-center justify-center">
      <img
        src={
          BACKGROUND_IMAGES.find((img) => img.value === rowData.data.bgImage)
            .images[0]
        }
        alt={rowData.data.bgImage}
        className="h-full w-28 rounded-lg object-cover"
      />
    </div>
  );
};

const DateTimeTemplate = ({ date }) => {
  return (
    <span className="text-xs italic text-gray-500">
      {new Date(date * 1000).toLocaleString()}
    </span>
  );
};

const ActionsTemplate = ({ rowData, setModal }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteSceneMutation = useMutation({
    mutationFn: deleteMarbleScene,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(["scenes"], (oldScenes) =>
        oldScenes.filter((scene) => scene.id !== variables),
      );
    },
  });

  const handleDuplicateScene = (sceneId, sceneData) => {
    const duplicateScene = {
      ...sceneData,
      title: rowData.data.title + " - Dev",
      createdAt: new Date(),
      lastEditedAt: new Date(),
      state: SCENE_STATES.DEVELOPMENT.value,
    };
    addMarbleScene()
      .then((res) => navigate(`/scenes/${res}`, { state: { duplicateScene } }))
      .catch((err) => console.error(err));
  };
  const handleEditScene = (sceneId) => navigate(`/scenes/${sceneId}`);

  const handleDeleteScene = (sceneId) => {
    confirmDialog({
      message: "Are you sure you want to delete this scene?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => deleteSceneMutation.mutate(sceneId),
    });
  };

  return (
    <div className="flex items-center justify-center gap-4">
      <Button
        onClick={() => {
          setModal({
            show: true,
            scene: rowData.data,
          });
        }}
        label="Preview"
        className="w-32"
      />
      <Button
        onClick={() =>
          rowData.data.state === SCENE_STATES.PUBLISHED.value
            ? handleDuplicateScene(rowData.id, rowData.data)
            : handleEditScene(rowData.id)
        }
        severity="info"
        label={
          rowData.data.state === SCENE_STATES.PUBLISHED.value
            ? "Duplicate"
            : "Edit"
        }
        className="w-32"
      />
      <Button
        onClick={() => handleDeleteScene(rowData.id)}
        severity="danger"
        label="Delete"
        className="w-32"
      />
    </div>
  );
};
