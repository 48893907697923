import React from "react";
import { Button } from "primereact/button";
import { useScenesStore } from "../store/store";
import {
  BACKGROUND_IMAGES,
  BALL_SCREEN_INITIAL_SIZE_PERCENTAGE,
  DEFAULT_BALL_DIAMETER,
  DEFAULT_DRAGGING_FORCE_PARAMETER,
  DEFAULT_PHYSICS_OPTIONS_ANDROID,
  DEFAULT_PHYSICS_OPTIONS_IOS,
  DEFAULT_PRESSURE_INDICATOR_COLOR,
  DEFAULT_SCALE,
  FORWARD_FORCE_FUNCTiON_ENUM,
  FORWARD_FORCE_FUNCTION_LIST,
  SCENE_STATES,
  TRANSITION_ELEMENT_INITIAL_RADIUS,
} from "../constants/constants";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ColorPicker } from "primereact/colorpicker";
import { Checkbox } from "primereact/checkbox";
import { ListBox } from "primereact/listbox";
import { pointOptions } from "../utilities/pointUtils";

const EditSceneSpecsTemplate = ({ setEditScene }) => {
  const scene = useScenesStore((state) => state.scene);
  const setScene = useScenesStore((state) => state.setScene);

  const defaultValues = {
    title: scene?.title || "",
    state: scene?.state || SCENE_STATES.DEVELOPMENT.value,
    scaleX: !isNaN(scene?.scale?.x) ? scene?.scale?.x : DEFAULT_SCALE.x,
    scaleY: !isNaN(scene?.scale?.y) ? scene?.scale?.y : DEFAULT_SCALE.y,
    snapToGrid: pointOptions.snapToGrid,
    ballDiameter: !isNaN(scene?.ballDiameter)
      ? scene?.ballDiameter
      : DEFAULT_BALL_DIAMETER,
    ballScreenSizePercentage: !isNaN(scene?.ballScreenSizePercentage)
      ? scene?.ballScreenSizePercentage
      : BALL_SCREEN_INITIAL_SIZE_PERCENTAGE,
    transitionElementRadius: !isNaN(scene?.transitionElementRadius)
      ? scene?.transitionElementRadius
      : TRANSITION_ELEMENT_INITIAL_RADIUS,
    bgImage: scene?.bgImage || "",
    draggingForceParameter: !isNaN(scene?.draggingForceParameter)
      ? scene?.draggingForceParameter
      : DEFAULT_DRAGGING_FORCE_PARAMETER,
    pressureIndicatorColor:
      scene?.pressureIndicatorColor || DEFAULT_PRESSURE_INDICATOR_COLOR,
    physics: {
      forwardForceFunction:
        scene?.physics?.forwardForceFunction ||
        FORWARD_FORCE_FUNCTiON_ENUM.DEFAULT.value,
      android: {
        ballMass: !isNaN(scene.physics?.android?.ballMass)
          ? scene.physics?.android?.ballMass
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.ballMass,
        forwardForce: !isNaN(scene.physics?.android?.forwardForce)
          ? scene.physics?.android?.forwardForce
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.forwardForce,
        jumpForce: !isNaN(scene.physics?.android?.jumpForce)
          ? scene.physics?.android?.jumpForce
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.jumpForce,
        jumpDistanceThreshold: !isNaN(
          scene.physics?.android?.jumpDistanceThreshold,
        )
          ? scene.physics?.android?.jumpDistanceThreshold
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.jumpDistanceThreshold,
        maximumVelocity: {
          force: !isNaN(scene.physics?.android?.maximumVelocity?.force)
            ? scene.physics?.android?.maximumVelocity?.force
            : DEFAULT_PHYSICS_OPTIONS_ANDROID.maximumVelocity.force,
          threshold: !isNaN(scene.physics?.android?.maximumVelocity?.threshold)
            ? scene.physics?.android?.maximumVelocity?.threshold
            : DEFAULT_PHYSICS_OPTIONS_ANDROID.maximumVelocity.threshold,
        },
        backgroundSpeed: !isNaN(scene.physics?.android?.backgroundSpeed)
          ? scene.physics?.android?.backgroundSpeed
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.backgroundSpeed,
        backgroundOpacity: !isNaN(scene.physics?.android?.backgroundOpacity)
          ? scene.physics?.android?.backgroundOpacity
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.backgroundOpacity,
      },
      ios: {
        ballMass: !isNaN(scene.physics?.ios?.ballMass)
          ? scene.physics?.ios?.ballMass
          : DEFAULT_PHYSICS_OPTIONS_IOS.ballMass,
        forwardForce: !isNaN(scene.physics?.ios?.forwardForce)
          ? scene.physics?.ios?.forwardForce
          : DEFAULT_PHYSICS_OPTIONS_IOS.forwardForce,
        jumpForce: !isNaN(scene.physics?.ios?.jumpForce)
          ? scene.physics?.ios?.jumpForce
          : DEFAULT_PHYSICS_OPTIONS_IOS.jumpForce,
        jumpDistanceThreshold: !isNaN(scene.physics?.ios?.jumpDistanceThreshold)
          ? scene.physics?.ios?.jumpDistanceThreshold
          : DEFAULT_PHYSICS_OPTIONS_IOS.jumpDistanceThreshold,
        maximumVelocity: {
          force: !isNaN(scene.physics?.ios?.maximumVelocity?.force)
            ? scene.physics?.ios?.maximumVelocity?.force
            : DEFAULT_PHYSICS_OPTIONS_IOS.maximumVelocity.force,
          threshold: !isNaN(scene.physics?.ios?.maximumVelocity?.threshold)
            ? scene.physics?.ios?.maximumVelocity?.threshold
            : DEFAULT_PHYSICS_OPTIONS_IOS.maximumVelocity.threshold,
        },
        backgroundSpeed: !isNaN(scene.physics?.ios?.backgroundSpeed)
          ? scene.physics?.ios?.backgroundSpeed
          : DEFAULT_PHYSICS_OPTIONS_IOS.backgroundSpeed,
        backgroundOpacity: !isNaN(scene.physics?.ios?.backgroundOpacity)
          ? scene.physics?.ios?.backgroundOpacity
          : DEFAULT_PHYSICS_OPTIONS_IOS.backgroundOpacity,
      },
    },
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const handleEditScene = (data) => {
    setScene({
      ...scene,
      title: data.title || scene?.id,
      state: data.state || scene.state,
      scale: {
        x: Number(data.scaleX),
        y: Number(data.scaleY),
      },
      ballDiameter: Number(data.ballDiameter),
      ballScreenSizePercentage: Number(data.ballScreenSizePercentage),
      transitionElementRadius: Number(data.transitionElementRadius),
      bgImage: data.bgImage || "",
      draggingForceParameter: Number(data?.draggingForceParameter),
      pressureIndicatorColor:
        data?.pressureIndicatorColor || DEFAULT_PRESSURE_INDICATOR_COLOR,
      physics: {
        forwardForceFunction:
          data?.physics?.forwardForceFunction ||
          FORWARD_FORCE_FUNCTiON_ENUM.DEFAULT.value,
        android: {
          ballMass: Number(data.physics.android.ballMass),
          forwardForce: Number(data.physics?.android?.forwardForce),
          jumpForce: Number(data.physics?.android?.jumpForce),
          jumpDistanceThreshold: Number(
            data.physics?.android?.jumpDistanceThreshold,
          ),
          maximumVelocity: {
            force: !isNaN(data.physics?.android?.maximumVelocity?.force)
              ? data.physics?.android?.maximumVelocity?.force
              : DEFAULT_PHYSICS_OPTIONS_ANDROID.maximumVelocity.force,
            threshold: Number(
              data.physics?.android?.maximumVelocity?.threshold,
            ),
          },
          backgroundSpeed: Number(data.physics?.android?.backgroundSpeed),
          backgroundOpacity: Number(data.physics?.android?.backgroundOpacity),
        },
        ios: {
          ballMass: Number(data.physics.ios.ballMass),
          forwardForce: Number(data.physics?.ios?.forwardForce),
          jumpForce: Number(data.physics?.ios?.jumpForce),
          jumpDistanceThreshold: Number(
            data.physics?.ios?.jumpDistanceThreshold,
          ),
          maximumVelocity: {
            force: !isNaN(data.physics?.ios?.maximumVelocity?.force)
              ? data.physics?.ios?.maximumVelocity?.force
              : DEFAULT_PHYSICS_OPTIONS_IOS.maximumVelocity.force,
            threshold: Number(data.physics?.ios?.maximumVelocity?.threshold),
          },
          backgroundSpeed: Number(data.physics?.ios?.backgroundSpeed),
          backgroundOpacity: Number(data.physics?.ios?.backgroundOpacity),
        },
      },
    });
    reset();
    setEditScene(false);
  };
  return (
    <form
      onSubmit={handleSubmit(handleEditScene)}
      className="mb-2 flex w-full flex-col gap-3"
    >
      {/* Title */}
      <div className="flex items-center gap-3">
        <p>Title:&nbsp;</p>
        <InputText
          {...register("title", { required: "Title is required" })}
          className="rounded-lg border border-slate-900 p-2"
        />
        {errors?.title && (
          <p className="text-red-500">{errors.title.message}</p>
        )}
      </div>
      {/* State */}
      <div className="flex items-center gap-3">
        <p>State:&nbsp;</p>
        <Controller
          control={control}
          name="state"
          render={({ field: { onChange, onBlur, value } }) => (
            <Dropdown
              value={value}
              onChange={onChange}
              options={Object.values(SCENE_STATES)}
              optionValue="value"
              optionLabel="label"
              placeholder="Select Scene State"
              className="rounded-lg border border-slate-900"
            />
          )}
        />

        {errors?.state && (
          <p className="text-red-500">{errors.state.message}</p>
        )}
      </div>
      {/* Scale */}
      <div className="flex items-center gap-3">
        <p>Scale:&nbsp;</p>
        {/* X Scale */}
        <div className="flex items-center gap-3">
          <InputText
            {...register("scaleX", {
              required: "Horizontal Scale is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.scaleX && (
            <p className="text-red-500">{errors.scaleX.message}</p>
          )}
        </div>
        {/* Y Scale */}
        <div className="flex items-center gap-3">
          <span>:</span>
          <InputText
            {...register("scaleY", {
              required: "Vertical Scale is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.scaleY && (
            <p className="text-red-500">{errors.scaleY.message}</p>
          )}
        </div>
      </div>
      {/* Ball Diameter */}
      <div className="flex items-center gap-3">
        <p>Ball Diameter:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("ballDiameter", {
              required: "Ball Diameter is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.ballDiameter && (
            <p className="text-red-500">{errors.ballDiameter.message}</p>
          )}
        </div>
      </div>
      {/* Ball Screen Size Percentage */}
      <div className="flex items-center gap-3">
        <p>Ball Diameter to Screen Width Percentage:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("ballScreenSizePercentage", {
              required: "Ball Screen Size Percentage is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.ballScreenSizePercentage && (
            <p className="text-red-500">
              {errors.ballScreenSizePercentage.message}
            </p>
          )}
        </div>
      </div>
      {/* Transition Element Radius */}
      <div className="flex items-center gap-3">
        <p>Transition Element Radius:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("transitionElementRadius", {
              required: "Transition Element Radius is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.transitionElementRadius && (
            <p className="text-red-500">
              {errors.transitionElementRadius.message}
            </p>
          )}
        </div>
      </div>
      {/* Dragging Force Parameter */}
      <div className="flex items-center gap-3">
        <p>Dragging Force Parameter:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("draggingForceParameter", {
              required: "Dragging Force Parameter is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.draggingForceParameter && (
            <p className="text-red-500">
              {errors.draggingForceParameter.message}
            </p>
          )}
        </div>
      </div>
      {/* Pressure Indicator Color Parameter */}
      <div className="flex items-center gap-3">
        <p>Pressure Indicator Color:&nbsp;</p>
        <Controller
          name={`pressureIndicatorColor`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div className="flex items-center gap-2">
              <ColorPicker
                name="pressureIndicatorColor"
                value={field.value}
                onChange={(e) => {
                  field.onChange({
                    target: {
                      name: e.target.name,
                      value: `#${e.target.value}`,
                    },
                  });
                }}
                pt={{
                  input: { className: "w-full p-6" },
                }}
                className="col-span-6"
              />
              <InputText
                name="pressureIndicatorColor"
                value={field.value}
                onChange={(e) => {
                  field.onChange({
                    target: {
                      name: e.target.name,
                      value: `${e.target.value}`,
                    },
                  });
                }}
                className="h-12 w-24 rounded-lg border border-slate-900 p-1"
              />
            </div>
          )}
        />
      </div>
      {/* Physics */}
      <div className="w-full">
        <p className="text-xl font-bold">Physics</p>
        {/* Forward Force Function Dropdown */}
        <div className="flex items-center gap-3">
          <p>Forward Force Function:&nbsp;</p>
          <div className="flex gap-3">
            <Controller
              name={`physics.forwardForceFunction`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Dropdown
                  {...field}
                  options={FORWARD_FORCE_FUNCTION_LIST}
                  optionValue="value"
                  optionLabel="label"
                  placeholder="Select Forward Force Function"
                  className="rounded-lg border border-slate-900"
                />
              )}
            />
          </div>
        </div>
        <div className="flex w-full gap-4 p-4">
          <div className="flex-1">
            <p className="text-lg font-semibold">Android</p>
            <div className="flex w-full flex-1 flex-col gap-3">
              {/* Ball Mass Parameter */}
              <div className="flex items-center gap-3">
                <p>Ball Mass:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.ballMass", {
                      required: "Ball mass is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.ballMass && (
                    <p className="text-red-500">
                      {errors.physics.android.ballMass.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Forward Force Scaler Parameter */}
              <div className="flex items-center gap-3">
                <p>Forward Force Scaler:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.forwardForce", {
                      required: "Forward force scaler is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.forwardForce && (
                    <p className="text-red-500">
                      {errors.physics.android.forwardForce.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Jump Force Scaler Parameter */}
              <div className="flex items-center gap-3">
                <p>Jump Force Scaler:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.jumpForce", {
                      required: "Jump force scaler is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.jumpForce && (
                    <p className="text-red-500">
                      {errors.physics.android.jumpForce.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Jump Distance Threshold Parameter */}
              <div className="flex items-center gap-3">
                <p>Jump Distance Threshold:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.jumpDistanceThreshold", {
                      required: "Jump distance threshold is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.jumpDistanceThreshold && (
                    <p className="text-red-500">
                      {errors.physics.android.jumpDistanceThreshold.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Force Maximum Velocity Parameter */}
              <div className="flex items-center gap-3">
                <p>Force Maximum Velocity:&nbsp;</p>

                <div className="flex items-center gap-3">
                  <Controller
                    name={`physics.android.maximumVelocity.force`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        // onChange={(e) => field.onChange(e.checked)}
                      />
                    )}
                  />

                  {errors?.physics?.android?.maximumVelocity?.force && (
                    <p className="text-red-500">
                      {errors.physics.android.maximumVelocity.force.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Maximum Velocity Threshold Parameter */}
              <div className="flex items-center gap-3">
                <p>Maximum Velocity Threshold:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.maximumVelocity.threshold", {
                      required: "Maximum velocity threshold is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    disabled={!watch("physics.android.maximumVelocity.force")}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.maximumVelocity?.threshold && (
                    <p className="text-red-500">
                      {errors.physics.android.maximumVelocity.threshold.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Background Speed Parameter */}
              <div className="flex items-center gap-3">
                <p>Background Speed:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.backgroundSpeed", {
                      required: "Background speed is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.backgroundSpeed && (
                    <p className="text-red-500">
                      {errors.physics.android.backgroundSpeed.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Background Opacity Parameter */}
              <div className="flex items-center gap-3">
                <p>Background Opacity:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.backgroundOpacity", {
                      required: "Background opacity is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.backgroundOpacity && (
                    <p className="text-red-500">
                      {errors.physics.android.backgroundOpacity.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <p className="text-lg font-semibold">IOS</p>
            <div className="flex w-full flex-1 flex-col gap-3">
              {/* Ball Mass Parameter */}
              <div className="flex items-center gap-3">
                <p>Ball Mass:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.ballMass", {
                      required: "Ball mass is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.ballMass && (
                    <p className="text-red-500">
                      {errors.physics.ios.ballMass.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Forward Force Scaler Parameter */}
              <div className="flex items-center gap-3">
                <p>Forward Force Scaler:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.forwardForce", {
                      required: "Forward force scaler is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.forwardForce && (
                    <p className="text-red-500">
                      {errors.physics.ios.forwardForce.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Jump Force Scaler Parameter */}
              <div className="flex items-center gap-3">
                <p>Jump Force Scaler:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.jumpForce", {
                      required: "Jump force scaler is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.jumpForce && (
                    <p className="text-red-500">
                      {errors.physics.ios.jumpForce.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Jump Distance Threshold Parameter */}
              <div className="flex items-center gap-3">
                <p>Jump Distance Threshold:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.jumpDistanceThreshold", {
                      required: "Jump distance threshold is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.jumpDistanceThreshold && (
                    <p className="text-red-500">
                      {errors.physics.ios.jumpDistanceThreshold.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Force Maximum Velocity Parameter */}
              <div className="flex items-center gap-3">
                <p>Force Maximum Velocity:&nbsp;</p>

                <div className="flex items-center gap-3">
                  <Controller
                    name={`physics.ios.maximumVelocity.force`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />

                  {errors?.physics?.ios?.maximumVelocity?.force && (
                    <p className="text-red-500">
                      {errors.physics.ios.maximumVelocity.force.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Maximum Velocity Threshold Parameter */}
              <div className="flex items-center gap-3">
                <p>Maximum Velocity Threshold:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.maximumVelocity.threshold", {
                      required: "Maximum velocity threshold is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    disabled={!watch("physics.ios.maximumVelocity.force")}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.maximumVelocity?.threshold && (
                    <p className="text-red-500">
                      {errors.physics.ios.maximumVelocity.threshold.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Background Speed Parameter */}
              <div className="flex items-center gap-3">
                <p>Background Speed:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.backgroundSpeed", {
                      required: "Background speed is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.backgroundSpeed && (
                    <p className="text-red-500">
                      {errors.physics.ios.backgroundSpeed.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Background Opacity Parameter */}
              <div className="flex items-center gap-3">
                <p>Background Opacity:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.backgroundOpacity", {
                      required: "Background opacity is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.backgroundOpacity && (
                    <p className="text-red-500">
                      {errors.physics.ios.backgroundOpacity.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Background List */}
      <div className="flex items-center gap-3">
        <ListBox
          {...register("bgImage", {
            required: false,
          })}
          options={BACKGROUND_IMAGES}
          itemTemplate={(option) => <BgImageListTemplate option={option} />}
          className="w-full p-4"
          pt={{
            list: { className: "flex items-center gap-3" },
            item: { className: "shrink-0 w-64 rounded-lg overflow-x-auto" },
          }}
        />
      </div>
      <div className="flex gap-3 self-end">
        <Button
          label="Cancel"
          severity="danger"
          type="button"
          onClick={() => {
            reset({
              title: scene?.title,
              scaleX: scene?.scale?.x,
              scaleY: scene?.scale?.y,
              bgImage: scene?.bgImage,
            });
            setEditScene(false);
          }}
        />
        <Button severity="success" label="Confirm" />
      </div>
    </form>
  );
};

export default EditSceneSpecsTemplate;

const BgImageListTemplate = ({ option }) => {
  return (
    <div className="flex w-full flex-col items-center gap-3">
      <img
        src={option.images[0]}
        alt={option.value}
        className="h-40 w-40 rounded-lg object-cover"
      />
      <p className="text-center">{option.value}</p>
    </div>
  );
};
