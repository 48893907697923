// Utilities
import {
  DEFAULT_BG_Y_Position,
  DEFAULT_COLOR,
  DEFAULT_PRESSURE_INDICATOR_COLOR,
  DEFAULT_PHYSICS_OPTIONS_ANDROID,
  FORWARD_FORCE_FUNCTiON_ENUM,
  DEFAULT_PHYSICS_OPTIONS_IOS,
} from "../constants/constants";
import { relativeToAbsolute } from "./dataConverter";
import { drawTransitionWithTangents } from "./drawTransitionWithTangents";
import { getElementAngle } from "./getElementAngle";
import { setSceneDimensions } from "./setSceneDimensions";

export const buildScene = (relativeScene) => {
  if (relativeScene === null) return null;

  const absoluteScene = {
    ...relativeScene,
    chains: [],
    bgYPosition: !isNaN(relativeScene.bgYPosition)
      ? relativeScene.bgYPosition
      : DEFAULT_BG_Y_Position,
    pressureIndicatorColor:
      relativeScene?.pressureIndicatorColor || DEFAULT_PRESSURE_INDICATOR_COLOR,
    physics: {
      forwardForceFunction:
        relativeScene?.physics?.forwardForceFunction ||
        FORWARD_FORCE_FUNCTiON_ENUM.DEFAULT.value,
      android: {
        ballMass: !isNaN(relativeScene.physics?.android?.ballMass)
          ? relativeScene.physics?.android?.ballMass
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.ballMass,
        forwardForce: !isNaN(relativeScene.physics?.android?.forwardForce)
          ? relativeScene.physics?.android?.forwardForce
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.forwardForce,
        jumpForce: !isNaN(relativeScene.physics?.android?.jumpForce)
          ? relativeScene.physics?.android?.jumpForce
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.jumpForce,
        jumpDistanceThreshold: !isNaN(
          relativeScene.physics?.android?.jumpDistanceThreshold,
        )
          ? relativeScene.physics?.android?.jumpDistanceThreshold
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.jumpDistanceThreshold,
        maximumVelocity: {
          force: !isNaN(relativeScene.physics?.android?.maximumVelocity?.force)
            ? relativeScene.physics?.android?.maximumVelocity?.force
            : DEFAULT_PHYSICS_OPTIONS_ANDROID.maximumVelocity.force,
          threshold: !isNaN(
            relativeScene.physics?.android?.maximumVelocity?.threshold,
          )
            ? relativeScene.physics?.android?.maximumVelocity?.threshold
            : DEFAULT_PHYSICS_OPTIONS_ANDROID.maximumVelocity.threshold,
        },
        backgroundSpeed: !isNaN(relativeScene.physics?.android?.backgroundSpeed)
          ? relativeScene.physics?.android?.backgroundSpeed
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.backgroundSpeed,
        backgroundOpacity: !isNaN(
          relativeScene.physics?.android?.backgroundOpacity,
        )
          ? relativeScene.physics?.android?.backgroundOpacity
          : DEFAULT_PHYSICS_OPTIONS_ANDROID.backgroundOpacity,
      },
      ios: {
        ballMass: !isNaN(relativeScene.physics?.ios?.ballMass)
          ? relativeScene.physics?.ios?.ballMass
          : DEFAULT_PHYSICS_OPTIONS_IOS.ballMass,
        forwardForce: !isNaN(relativeScene.physics?.ios?.forwardForce)
          ? relativeScene.physics?.ios?.forwardForce
          : DEFAULT_PHYSICS_OPTIONS_IOS.forwardForce,
        jumpForce: !isNaN(relativeScene.physics?.ios?.jumpForce)
          ? relativeScene.physics?.ios?.jumpForce
          : DEFAULT_PHYSICS_OPTIONS_IOS.jumpForce,
        jumpDistanceThreshold: !isNaN(
          relativeScene.physics?.ios?.jumpDistanceThreshold,
        )
          ? relativeScene.physics?.ios?.jumpDistanceThreshold
          : DEFAULT_PHYSICS_OPTIONS_IOS.jumpDistanceThreshold,
        maximumVelocity: {
          force: !isNaN(relativeScene.physics?.ios?.maximumVelocity?.force)
            ? relativeScene.physics?.ios?.maximumVelocity?.force
            : DEFAULT_PHYSICS_OPTIONS_IOS.maximumVelocity.force,

          threshold: !isNaN(
            relativeScene.physics?.ios?.maximumVelocity?.threshold,
          )
            ? relativeScene.physics?.ios?.maximumVelocity?.threshold
            : DEFAULT_PHYSICS_OPTIONS_IOS.maximumVelocity.threshold,
        },
        backgroundSpeed: !isNaN(relativeScene.physics?.ios?.backgroundSpeed)
          ? relativeScene.physics?.ios?.backgroundSpeed
          : DEFAULT_PHYSICS_OPTIONS_IOS.backgroundSpeed,
        backgroundOpacity: !isNaN(relativeScene.physics?.ios?.backgroundOpacity)
          ? relativeScene.physics?.ios?.backgroundOpacity
          : DEFAULT_PHYSICS_OPTIONS_IOS.backgroundOpacity,
      },
    },
  };

  relativeScene.chains.forEach((chain) => {
    const elements = [];
    chain.elements.forEach((element, elementIndex) => {
      let firstElementAngle;
      let secondElementAngle;

      if (elementIndex !== 0) {
        firstElementAngle = getElementAngle(
          chain.elements[elementIndex - 1],
          chain.elements[elementIndex - 1].points.secondControlPoint,
          chain.elements[elementIndex - 1].points.endPoint,
        );
        secondElementAngle = getElementAngle(
          element,
          element.points.startPoint,
          element.points.firstControlPoint,
        );
      }

      const noTransition =
        elementIndex === 0 ||
        element.type === 4 ||
        chain.elements[elementIndex - 1].type === 4 ||
        element.type === 3 ||
        chain.elements[elementIndex - 1].type === 3 ||
        firstElementAngle === secondElementAngle;

      if (noTransition) {
        // Convert element to absolute coordinates
        const absoluteElement = relativeToAbsolute(
          elements[elements.length - 1],
          element,
          elementIndex,
          chain.startPoint,
          relativeScene.scale,
        );
        elements.push(absoluteElement);
      } else {
        // Build transition element
        const transitionElement = drawTransitionWithTangents(
          chain.elements[elementIndex - 1],
          element,
          relativeScene.transitionElementRadius,
        );

        // Convert transition to absolute coordinates
        const absoluteTransitionElement = relativeToAbsolute(
          elements[elements.length - 1],
          transitionElement,
          elementIndex,
          chain.startPoint,
          relativeScene.scale,
        );

        // Convert element to absolute coordinates
        const absoluteElement = relativeToAbsolute(
          absoluteTransitionElement,
          element,
          elementIndex,
          chain.startPoint,
          relativeScene.scale,
        );
        elements.push(absoluteTransitionElement);
        elements.push(absoluteElement);
      }
    });
    absoluteScene.chains.push({ elements, startPoint: chain.startPoint });
  });

  absoluteScene.startPoint = {
    x: absoluteScene.startPoint.x,
    y: absoluteScene.startPoint.y,
  };
  absoluteScene.sceneDimensions = setSceneDimensions(absoluteScene);
  return absoluteScene;
};
