import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

// Store
import { useObjectStore, useScenesStore } from "../store/store";

// Services
import {
  fetchMarbleScene,
  fetchMarbleScenes,
  updateMarbleScene,
} from "../services/marbleScenes";

// Utilities
import { absoluteToRelative } from "../utilities/dataConverter";
import { buildScene } from "../utilities/buildScene";

// Pages
import { Loading } from "./Loading";

// Components
import { Modal } from "../components/Modal";
import { PreviewScene } from "../components/PreviewScene";
import { Chain } from "../components/Chain";

// Prime React Components
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import SceneSpecsTemplate from "../components/SceneScepsTemplate";
import EditSceneSpecsTemplate from "../components/EditSceneSpecsTemplate";

export const Scene = () => {
  // let activated = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { sceneId } = useParams();
  const { setFocusedObject } = useObjectStore();
  const scene = useScenesStore((state) => state.scene);
  const setScene = useScenesStore((state) => state.setScene);
  const [editScene, setEditScene] = useState(false);
  const [scenesOptions, setScenesOptions] = useState([]);
  const [previewScene, setPreviewScene] = useState(false);
  const [saveToIdDropdown, setSaveToIdDropdown] = useState({
    saveToId: sceneId,
    isOpen: false,
  });
  const [modal, setModal] = useState({
    show: false,
    chainIndex: 0,
    elementIndex: 0,
    element: null,
  });

  const { data, isLoading } = useQuery({
    queryKey: ["scenes", sceneId],
    queryFn: () => fetchMarbleScene(sceneId),
    select: useCallback((data) => absoluteToRelative(data), []),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const scenesQuery = useQuery({
    queryKey: ["scenes"],
    queryFn: fetchMarbleScenes,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (location.state) {
      const relativeScene = absoluteToRelative(location.state.duplicateScene);
      setScene(relativeScene);
    } else {
      setScene(data);
    }
  }, [data, location.state]);

  useEffect(() => {
    const allScenes = [
      {
        id: sceneId,
        title: "Current Scene",
      },
    ];

    if (scenesQuery.data) {
      scenesQuery.data.forEach((option) => {
        if (option.id !== sceneId)
          allScenes.push({
            id: option.id,
            title: option.data.title,
          });
      });
    }

    if (allScenes.every((scene) => scene.id !== sceneId)) {
      allScenes.unshift({
        id: sceneId,
        title: "Current Scene",
      });
    }

    setScenesOptions(allScenes);
  }, [scenesQuery.data]);

  const absoluteScene = useMemo(() => scene && buildScene(scene), [scene]);

  const updateSceneMutation = useMutation({
    mutationFn: ({ saveToId, absoluteScene }) => {
      updateMarbleScene(sceneId, {
        ...absoluteScene,
        lastEditedAt: new Date(),
      });

      if (saveToId !== sceneId) {
        fetchMarbleScene(saveToId)
          .then((saveToScene) => {
            updateMarbleScene(saveToId, {
              ...absoluteScene,
              title: saveToScene.title,
              createdAt: saveToScene.createdAt,
              state: saveToScene.state,
              lastEditedAt: new Date(),
            });
          })
          .catch((error) => console.error(error));
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["scenes", sceneId],
      });
      navigate(-1);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const getSceneTitleById = (id) => {
    const scene = scenesOptions.find((item) => item.id === id);
    return scene ? scene.title : "Title not found";
  };

  // if (data && !activated.current) {
  //   activated.current = true;
  //   setScene(data);
  // }
  if (isLoading) return <Loading />;

  return (
    <div className="relative">
      <ConfirmDialog />
      {/* Preview Scene Action Button */}
      <Button
        data-pr-tooltip="Preview"
        onClick={() => setPreviewScene(true)}
        pt={{ label: { style: { display: "none" } } }}
        className="custom-tooltip fixed right-20 top-[6.25rem] h-[3.125rem] w-[3.125rem] p-4"
        icon="pi pi-eye"
      />

      {/* Scene Modal */}
      <Dialog
        header={scene?.title}
        visible={previewScene}
        blockScroll
        onHide={() => setPreviewScene(false)}
        className={`${
          window.innerWidth > window.innerHeight
            ? "h-[90vh] w-[80vh]"
            : "h-[90vw] w-[80vw]"
        }`}
        pt={{
          content: { className: "overflow-hidden" },
        }}
      >
        <PreviewScene
          absoluteScene={absoluteScene}
          scenesOptions={scenesOptions}
          editScene={true}
          setScene={setScene}
        />
      </Dialog>

      {/* Element Modal */}
      <Dialog
        header={scene?.title}
        visible={modal.show}
        blockScroll
        onHide={() => {
          setFocusedObject({ index: null, type: "", animate: false });
          setModal({ ...modal, show: false });
        }}
        className={`${
          window.innerWidth > window.innerHeight ? "w-[120vh]" : "w-[80vw]"
        }`}
        pt={{
          content: { className: "overflow-hidden" },
        }}
      >
        <Modal
          chainIndex={modal.chainIndex}
          elementIndex={modal.elementIndex}
          elementToUpdate={modal.element}
          setModal={setModal}
          scenesOptions={scenesOptions}
        />
      </Dialog>

      {/* Scene */}
      <div className="flex w-full gap-12 px-24 py-0">
        <div className="flex w-full flex-col items-center gap-3">
          {/* Scene Header */}
          {editScene ? (
            <EditSceneSpecsTemplate setEditScene={setEditScene} />
          ) : (
            <SceneSpecsTemplate
              absoluteScene={absoluteScene}
              scenesOptions={scenesOptions}
              setEditScene={setEditScene}
              updateSceneMutation={updateSceneMutation}
            />
          )}
          {/* Scene Body */}
          <div className="flex w-full flex-col gap-3 rounded-lg border border-slate-900 p-4">
            {/* Chain Sort Buttons Tooltip */}
            <Tooltip target=".custom-tooltip" position="top" showDelay={700} />
            {/* Chains Map */}
            {scene?.chains.map((chain, chainIndex) => (
              <div
                key={chainIndex}
                className="flex flex-col gap-3 rounded-lg border border-slate-900 p-4"
              >
                <Chain chainIndex={chainIndex} setModal={setModal} />
              </div>
            ))}
            {/* Scene Action Buttons */}
            <div className="flex justify-end gap-3">
              <Button
                label="Cancel"
                severity="danger"
                type="button"
                onClick={() => navigate(-1)}
              />
              <div className="flex">
                <Button
                  label="Save Changes"
                  severity="success"
                  onClick={() => {
                    const saveScene = () => {
                      updateSceneMutation.mutate({
                        saveToId: saveToIdDropdown.saveToId,
                        absoluteScene,
                      });
                    };

                    if (sceneId !== saveToIdDropdown.saveToId) {
                      confirmDialog({
                        message: (
                          <p>
                            Are you sure you want to save changes to the
                            scene&nbsp;
                            <strong>
                              {getSceneTitleById(saveToIdDropdown.saveToId)}
                            </strong>
                            ? This action will overwrite any existing data.
                          </p>
                        ),
                        header: "Confirmation",
                        className: "max-w-[95vw] sm:max-w-[50vw]",
                        icon: "pi pi-exclamation-triangle",
                        accept: saveScene,
                      });
                    } else {
                      saveScene();
                    }
                  }}
                  className="rounded-r-none shadow-none"
                />
                <div className="relative">
                  <Button
                    icon={`pi ${saveToIdDropdown.isOpen ? "pi-angle-up" : "pi-angle-down"}`}
                    severity="success"
                    onClick={() =>
                      setSaveToIdDropdown({
                        ...saveToIdDropdown,
                        isOpen: !saveToIdDropdown.isOpen,
                      })
                    }
                    className="rounded-l-none px-1 text-center shadow-none"
                  />
                  {saveToIdDropdown.isOpen ? (
                    <div className="absolute bottom-16 right-0 max-h-80 w-72 divide-y-2 overflow-y-auto rounded-md border border-slate-700 bg-white">
                      <p className="bg-slate-200 px-4 py-2 font-bold">
                        Save to Scene
                      </p>
                      <ul className="divide-y">
                        {scenesOptions.map((scene) => {
                          return (
                            <li
                              key={scene.id}
                              onClick={() =>
                                setSaveToIdDropdown({
                                  saveToId: scene.id,
                                  isOpen: false,
                                })
                              }
                              className="flex items-center justify-between gap-4 px-6 py-2 hover:bg-slate-100"
                            >
                              <span>{scene.title}</span>

                              {saveToIdDropdown.saveToId === scene.id ? (
                                <i className="pi pi-check" />
                              ) : null}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
