import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useScenesStore } from "../store/store";
import {
  BACKGROUND_IMAGES,
  BACKGROUND_IMAGES_ENUM,
  BALL_SCREEN_INITIAL_SIZE_PERCENTAGE,
  DEFAULT_BALL_DIAMETER,
  DEFAULT_DRAGGING_FORCE_PARAMETER,
  DEFAULT_PHYSICS_OPTIONS_ANDROID,
  DEFAULT_PRESSURE_INDICATOR_COLOR,
  DEFAULT_SCALE,
  FORWARD_FORCE_FUNCTiON_ENUM,
  SCENE_STATES,
  TRANSITION_ELEMENT_INITIAL_RADIUS,
} from "../constants/constants";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { confirmDialog } from "primereact/confirmdialog";

const SceneSpecsTemplate = ({
  setEditScene,
  updateSceneMutation,
  absoluteScene,
  scenesOptions,
}) => {
  const navigate = useNavigate();
  const { sceneId } = useParams();
  const [saveToIdDropdown, setSaveToIdDropdown] = useState({
    saveToId: sceneId,
    isOpen: false,
  });

  const scene = useScenesStore((state) => state.scene);
  const state = Object.keys(SCENE_STATES).find(
    (key) => SCENE_STATES[key].value === scene?.state,
  );
  const getSceneTitleById = (id) => {
    const scene = scenesOptions.find((item) => item.id === id);
    return scene ? scene.title : "Title not found";
  };
  return (
    <div className="align-center mb-2 flex min-h-24 w-full justify-between gap-3">
      <div className="flex flex-1 justify-between gap-6">
        <div>
          <div className="mb-4 flex items-center justify-between gap-4">
            <h1 className="w-fit text-5xl">{scene?.title}</h1>
            {state ? (
              <Tag
                rounded
                severity={SCENE_STATES[state].severity}
                value={SCENE_STATES[state].label}
              />
            ) : null}
          </div>
          <div className="pl-8">
            <p className="w-fit">
              Scale:{" "}
              {!isNaN(scene?.scale?.x) ? scene?.scale?.x : DEFAULT_SCALE.x} :{" "}
              {!isNaN(scene?.scale?.y) ? scene?.scale?.y : DEFAULT_SCALE.y}
            </p>
            <p className="w-fit">
              Ball Diameter:&nbsp;
              {!isNaN(scene?.ballDiameter)
                ? scene?.ballDiameter
                : DEFAULT_BALL_DIAMETER}
              &nbsp;<strong className="italic">(units)</strong>
            </p>
            <p className="w-fit">
              Ball Diameter to Screen Width Percentage:&nbsp;
              {!isNaN(scene?.ballScreenSizePercentage)
                ? scene?.ballScreenSizePercentage
                : BALL_SCREEN_INITIAL_SIZE_PERCENTAGE}
              <strong className="italic">&#37;</strong>
            </p>
            <p className="w-fit">
              Transition Element Radius:&nbsp;
              {!isNaN(scene?.transitionElementRadius)
                ? scene?.transitionElementRadius
                : TRANSITION_ELEMENT_INITIAL_RADIUS}
              &nbsp;<strong className="italic">(units)</strong>
            </p>
            <p className="w-fit">
              Dragging Force Parameter:&nbsp;
              {!isNaN(scene?.draggingForceParameter)
                ? scene?.draggingForceParameter
                : DEFAULT_DRAGGING_FORCE_PARAMETER}
              &nbsp;
              <strong className="italic">
                (N per (m/s)<sup>2</sup>)
              </strong>
            </p>

            <p className="flex w-fit items-center">
              Pressure Indicator Color:&nbsp;
              <div
                className={`h-6 w-16 rounded-lg`}
                style={{
                  backgroundColor:
                    scene?.pressureIndicatorColor ||
                    DEFAULT_PRESSURE_INDICATOR_COLOR,
                }}
              />
            </p>
            <div className="w-full py-4">
              <p className="text-xl font-bold">Physics</p>
              <p className="w-fit">
                Forward Force Function:&nbsp;
                {scene?.physics?.forwardForceFunction ||
                  FORWARD_FORCE_FUNCTiON_ENUM.DEFAULT.label}
              </p>
              <div className="flex w-full gap-4 p-2">
                <div className="flex-1">
                  <p className="text-lg font-semibold">Android</p>
                  <div className="flex w-full flex-1 flex-col gap-3">
                    {/* Ball Mass Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Ball Mass:&nbsp;
                        {!isNaN(scene?.physics?.android?.ballMass)
                          ? scene.physics?.android?.ballMass
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.ballMass}
                      </p>
                    </div>
                    {/* Forward Force Scaler Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Forward Force Scaler:&nbsp;
                        {!isNaN(scene?.physics?.android?.forwardForce)
                          ? scene.physics?.android?.forwardForce
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.forwardForce}
                      </p>
                    </div>
                    {/* Jump Force Scaler Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Jump Force Scaler:&nbsp;
                        {!isNaN(scene?.physics?.android?.jumpForce)
                          ? scene.physics?.android?.jumpForce
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.jumpForce}
                      </p>
                    </div>
                    {/* Jump Distance Threshold Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Jump Distance Threshold:&nbsp;
                        {!isNaN(scene?.physics?.android?.jumpDistanceThreshold)
                          ? scene.physics?.android?.jumpDistanceThreshold
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.jumpDistanceThreshold}
                        %
                      </p>
                    </div>
                    {/* Force Maximum Velocity Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Force Maximum Velocity:&nbsp;
                        {scene?.physics?.android?.maximumVelocity?.force
                          ? !isNaN(
                              scene?.physics?.android?.maximumVelocity
                                ?.threshold,
                            )
                            ? scene?.physics?.android?.maximumVelocity
                                ?.threshold
                            : DEFAULT_PHYSICS_OPTIONS_ANDROID.maximumVelocity
                                .threshold
                          : "Disabled"}
                      </p>
                    </div>

                    {/* Background Speed Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Background Speed:&nbsp;
                        {!isNaN(scene?.physics?.android?.backgroundSpeed)
                          ? scene.physics?.android?.backgroundSpeed
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.backgroundSpeed}
                      </p>
                    </div>
                    {/* Background Opacity Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Background Opacity:&nbsp;
                        {!isNaN(scene?.physics?.android?.backgroundOpacity)
                          ? scene.physics?.android?.backgroundOpacity
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.backgroundOpacity}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <p className="text-lg font-semibold">IOS</p>
                  <div className="flex w-full flex-1 flex-col gap-3">
                    {/* Ball Mass Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Ball Mass:&nbsp;
                        {!isNaN(scene?.physics?.ios?.ballMass)
                          ? scene.physics?.ios?.ballMass
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.ballMass}
                      </p>
                    </div>
                    {/* Forward Force Scaler Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Forward Force Scaler:&nbsp;
                        {!isNaN(scene?.physics?.ios?.forwardForce)
                          ? scene.physics?.ios?.forwardForce
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.forwardForce}
                      </p>
                    </div>
                    {/* Jump Force Scaler Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Jump Force Scaler:&nbsp;
                        {!isNaN(scene?.physics?.ios?.jumpForce)
                          ? scene.physics?.ios?.jumpForce
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.jumpForce}
                      </p>
                    </div>
                    {/* Jump Distance Threshold Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Jump Distance Threshold:&nbsp;
                        {!isNaN(scene?.physics?.ios?.jumpDistanceThreshold)
                          ? scene.physics?.ios?.jumpDistanceThreshold
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.jumpDistanceThreshold}
                        %
                      </p>
                    </div>
                    {/* Force Maximum Velocity Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Force Maximum Velocity:&nbsp;
                        {scene?.physics?.ios?.maximumVelocity?.force
                          ? !isNaN(
                              scene?.physics?.ios?.maximumVelocity?.threshold,
                            )
                            ? scene?.physics?.ios?.maximumVelocity?.threshold
                            : DEFAULT_PHYSICS_OPTIONS_ANDROID.maximumVelocity
                                .threshold
                          : "Disabled"}
                      </p>
                    </div>

                    {/* Background Speed Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Background Speed:&nbsp;
                        {!isNaN(scene?.physics?.ios?.backgroundSpeed)
                          ? scene.physics?.ios?.backgroundSpeed
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.backgroundSpeed}
                      </p>
                    </div>
                    {/* Background Opacity Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Background Opacity:&nbsp;
                        {!isNaN(scene?.physics?.ios?.backgroundOpacity)
                          ? scene.physics?.ios?.backgroundOpacity
                          : DEFAULT_PHYSICS_OPTIONS_ANDROID.backgroundOpacity}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {scene?.bgImage &&
          scene.bgImage !== BACKGROUND_IMAGES_ENUM.NONE.value && (
            <img
              src={
                BACKGROUND_IMAGES.find((img) => img.value === scene.bgImage)
                  .images[0]
              }
              alt={scene.bgImage}
              className="aspect-auto size-52 rounded-lg object-cover"
            />
          )}
      </div>
      <div className="space-y-2">
        <Button
          onClick={() => setEditScene(true)}
          severity="info"
          label="Edit Scene"
          className="w-full whitespace-nowrap"
        />
        <div className="flex">
          <Button
            label="Save Changes"
            severity="success"
            onClick={() => {
              const saveScene = () => {
                updateSceneMutation.mutate({
                  saveToId: saveToIdDropdown.saveToId,
                  absoluteScene,
                });
              };

              if (sceneId !== saveToIdDropdown.saveToId) {
                confirmDialog({
                  message: (
                    <p>
                      Are you sure you want to save changes to the scene&nbsp;
                      <strong>
                        {getSceneTitleById(saveToIdDropdown.saveToId)}
                      </strong>
                      ? This action will overwrite any existing data.
                    </p>
                  ),
                  header: "Confirmation",
                  className: "max-w-[95vw] sm:max-w-[50vw]",
                  icon: "pi pi-exclamation-triangle",
                  accept: saveScene,
                });
              } else {
                saveScene();
              }
            }}
            className="rounded-r-none shadow-none"
          />
          <div className="relative">
            <Button
              icon={`pi ${saveToIdDropdown.isOpen ? "pi-angle-up" : "pi-angle-down"}`}
              severity="success"
              onClick={() =>
                setSaveToIdDropdown({
                  ...saveToIdDropdown,
                  isOpen: !saveToIdDropdown.isOpen,
                })
              }
              className="rounded-l-none px-1 text-center shadow-none"
            />
            {saveToIdDropdown.isOpen ? (
              <div className="absolute right-0 top-16 max-h-80 w-72 divide-y-2 overflow-y-auto rounded-md border border-slate-700 bg-white">
                <p className="bg-slate-200 px-4 py-2 font-bold">
                  Save to Scene
                </p>
                <ul className="divide-y">
                  {scenesOptions.map((scene) => {
                    return (
                      <li
                        key={scene.id}
                        onClick={() =>
                          setSaveToIdDropdown({
                            saveToId: scene.id,
                            isOpen: false,
                          })
                        }
                        className="flex items-center justify-between gap-4 px-6 py-2 hover:bg-slate-100"
                      >
                        <span>{scene.title}</span>

                        {saveToIdDropdown.saveToId === scene.id ? (
                          <i className="pi pi-check" />
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        <Button
          label="Cancel"
          severity="danger"
          type="button"
          onClick={() => navigate(-1)}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default SceneSpecsTemplate;
