import React, { useRef } from "react";

// Constants
import { ANIMATION_TYPES, OBSTACLE_TYPES } from "../constants/constants";

// Prime react components
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import { ColorPicker } from "primereact/colorpicker";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import ColorPaletteOverlay from "./ColorPaletteOverlay";

export const ObstacleSettings = ({
  obstacle,
  handleObjectChange,
  handleAnimation,
}) => {
  const overlayRef = useRef();

  return (
    <>
      {/* Stroke Color */}
      <div className="grid grid-cols-12 items-center gap-4">
        <span className="col-span-4">Outline Color</span>
        <ColorPaletteOverlay
          overlayRef={overlayRef}
          handleColorClick={(e, item) => {
            overlayRef.current.toggle(e);
            handleObjectChange("outline", item.hex);
          }}
        />
        <Button
          icon="pi pi-palette"
          onClick={(e) => overlayRef.current.toggle(e)}
          type="button"
          className="col-span-2"
        />
        <ColorPicker
          name="color"
          value={obstacle?.outline}
          onChange={(e) => handleObjectChange("outline", "#" + e.target.value)}
          className="col-span-2 pl-1"
          pt={{
            input: { className: "w-full p-6" },
          }}
        />
        <InputText
          name="colorText"
          value={obstacle?.outline}
          onChange={(e) => handleObjectChange("outline", e.target.value)}
          className="col-span-3 h-12 rounded-lg border border-slate-900 p-1"
        />
      </div>

      {/* Width */}
      <div className="grid grid-cols-12 items-center gap-3">
        <label
          className={`col-span-4 ${obstacle?.type === OBSTACLE_TYPES.CURVE.value && "text-gray-500"}`}
        >
          Width
        </label>
        <InputText
          keyfilter="num"
          name="width"
          value={obstacle?.width}
          onChange={(e) => handleObjectChange(e.target.name, e.target.value)}
          disabled={obstacle?.type === OBSTACLE_TYPES.CURVE.value}
          className="col-span-8"
        />
      </div>

      {/* Height */}
      <div className="grid grid-cols-12 items-center gap-3">
        <label
          className={`col-span-4 ${obstacle?.type === OBSTACLE_TYPES.CURVE.value && "text-gray-500"}`}
        >
          Height
        </label>
        <InputText
          keyfilter="num"
          name="height"
          value={obstacle?.height}
          onChange={(e) => handleObjectChange(e.target.name, e.target.value)}
          disabled={obstacle?.type === OBSTACLE_TYPES.CURVE.value}
          className="col-span-8"
        />
      </div>

      {/* Line Width */}
      <div className="grid grid-cols-12 items-center gap-3">
        <label className="col-span-4">Line Width</label>
        <InputText
          keyfilter="num"
          name="lineWidth"
          value={obstacle?.lineWidth}
          onChange={(e) => handleObjectChange(e.target.name, e.target.value)}
          className="col-span-8"
        />
      </div>

      {/* Friction */}
      <div className="grid grid-cols-12 items-center gap-3">
        <label className="col-span-4">Friction</label>
        <InputText
          keyfilter="num"
          name="friction"
          value={obstacle?.friction}
          onChange={(e) => handleObjectChange(e.target.name, e.target.value)}
          className="col-span-8"
        />
      </div>

      {/* Restitution */}
      <div className="grid grid-cols-12 items-center gap-3">
        <label className="col-span-4">Restitution</label>
        <InputText
          keyfilter="num"
          name="restitution"
          value={obstacle?.restitution}
          onChange={(e) => handleObjectChange(e.target.name, e.target.value)}
          className="col-span-8"
        />
      </div>

      {/* Rotation */}
      <div className="grid grid-cols-12 items-center gap-4">
        <span
          className={`col-span-4 ${obstacle?.type === OBSTACLE_TYPES.CURVE.value && "text-gray-500"}`}
        >
          Rotation
        </span>
        <Slider
          max={360}
          min={0}
          value={obstacle?.rotation}
          onChange={(e) => handleObjectChange("rotation", e.value)}
          disabled={obstacle?.type === OBSTACLE_TYPES.CURVE.value}
          className="col-span-5"
        />

        <InputText
          keyfilter="int"
          value={obstacle?.rotation}
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              handleObjectChange("rotation", 0);
              return;
            } else if (e.target.value >= 360) {
              handleObjectChange("rotation", 360);
              return;
            }
            handleObjectChange("rotation", e.target.value);
          }}
          disabled={obstacle?.type === OBSTACLE_TYPES.CURVE.value}
          className="col-span-3"
        />
      </div>

      {/* Animation Rotation */}
      <div className="flex flex-col gap-3 rounded-lg bg-slate-100 p-4">
        <h3 className="font-semibold">Animation</h3>
        <div className="space-y-3">
          <div className="flex items-center gap-1">
            <RadioButton
              inputId="none"
              name="animationType"
              value={ANIMATION_TYPES.NONE}
              onChange={handleAnimation}
              checked={obstacle?.animationType === ANIMATION_TYPES.NONE}
            />
            <label htmlFor="none">None</label>
          </div>
          <div className="flex items-center gap-1">
            <RadioButton
              inputId="rotation"
              name="animationType"
              value={ANIMATION_TYPES.ROTATION}
              onChange={handleAnimation}
              checked={obstacle?.animationType === ANIMATION_TYPES.ROTATION}
            />
            <label htmlFor="rotation">Rotation</label>
          </div>
          <div className="flex items-center gap-1">
            <RadioButton
              inputId="translation"
              name="animationType"
              value={ANIMATION_TYPES.SINUSOIDAL}
              onChange={handleAnimation}
              checked={obstacle?.animationType === ANIMATION_TYPES.SINUSOIDAL}
            />
            <label htmlFor="translation">Sinusoidal</label>
          </div>
        </div>
        {/* Speed */}
        <div className="grid grid-cols-12 items-center gap-3">
          <label className="col-span-4">
            {obstacle?.animationType === ANIMATION_TYPES.SINUSOIDAL
              ? "Time Period"
              : "Speed"}
            <sub className="block text-start text-[10px]">
              {obstacle?.animationType === ANIMATION_TYPES.ROTATION
                ? "( degree / second )"
                : obstacle?.animationType === ANIMATION_TYPES.SINUSOIDAL
                  ? "( second )"
                  : null}
            </sub>
          </label>
          <InputText
            keyfilter="num"
            name="speed"
            value={obstacle?.animation?.speed || 1}
            onChange={handleAnimation}
            disabled={obstacle?.animationType === ANIMATION_TYPES.NONE}
            className="col-span-8"
          />
        </div>
        {/* Preview Buttons */}
        {/* <Button
          name="animate"
          label="Preview"
          type="button"
          onClick={() => {
            if (!focusedObject?.animate)
              setFocusedObject({ ...focusedObject, animate: true });
          }}
          disabled={
            Number(obstacle.animation.speed) === 0 ||
            Number(obstacle.animation.speed) === null ||
            focusedObject?.animate
          }
        /> */}
      </div>
    </>
  );
};
